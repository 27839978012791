import React, { useState, useEffect } from "react";
import { AppLink } from "@base";
import { MobileHeader, LanguageToggle, Socials } from "@molecules";
import { useAppState } from "@state";
import { m } from "framer-motion";
import classNames from "classnames";
import { toggleBodyScroll } from "@utils";
import useHeaderData from "@staticQueries/HeaderQuery";
import useFooterData from "@staticQueries/FooterQuery";
import LogoEn from "@svg/logo.svg";
import LogoFr from "@svg/logoFr.svg";

const Header = ({ locales, lang, pageLang, inverse }) => {
  const [{ layout }, dispatch] = useAppState();
  // Mobile nav triggers
  const { hideNav } = layout;

  const [background, setBackground] = useState(inverse ? "viridian" : "coral");
  const [visibleImage, setVisibleImage] = useState(null);

  useEffect(() => {
    toggleBodyScroll(hideNav);

    if (!hideNav) {
      document.documentElement.classList.add("is-locked");
    } else {
      document.documentElement.classList.remove("is-locked");
    }
  }, [hideNav]);

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "2",
  };

  // Placeholder to be replaced with useHeaderData
  // or other static queries
  const { nav, subnav } = useHeaderData()[lang === "en" ? "en" : "fr"];
  const { socials } = useFooterData()[lang === "en" ? "en" : "fr"];

  return (
    <header
      className={classNames("absolute z-50 h-14 w-full", {
        "text-white": inverse,
        "text-black": !inverse,
      })}
    >
      <div className="relative z-20 flex h-14 items-center justify-between gap-8 border-b-3 border-current p-2">
        <nav aria-label="Header navigation" className="flex gap-8">
          <button
            type="button"
            className="flex h-8 w-8 items-center justify-center place-self-center rounded-full border-2 border-current"
            aria-label="Toggle mobile navigation"
            onClick={() => {
              dispatch({
                type: hideNav ? "showNav" : "hideNav",
              });
            }}
          >
            {/* Hamburger Menu with animated SVG */}
            <span className="icon flex place-self-center">
              <svg width="30" height="30" viewBox="0 0 30 30">
                <m.path
                  {...iconStyles}
                  variants={{
                    closed: { d: "M30 10L0 10" },
                    open: { d: "M28 28L2 4" },
                  }}
                />
                <m.path
                  {...iconStyles}
                  variants={{
                    closed: { d: "M30 15L0 15" },
                    open: { d: "M28 4L2 28" },
                  }}
                />
                <m.path
                  {...iconStyles}
                  variants={{
                    closed: { d: "M30 20L0 20" },
                    open: { d: "M28 4L2 28" },
                  }}
                />
              </svg>
            </span>
          </button>
          <AppLink to="/" className="flex h-9 items-center">
            {lang === "en" && (
              <span className="icon--fit-height h-9 overflow-visible">
                <LogoEn />
              </span>
            )}
            {lang === "fr" && (
              <span className="icon--fit-height h-11 overflow-visible">
                <LogoFr />
              </span>
            )}
          </AppLink>
        </nav>

        <div className="mr-0 flex items-center gap-8 md:mr-6">
          <LanguageToggle
            hideNav={hideNav}
            inverse={inverse}
            lang={lang}
            locales={locales}
            pageLang={pageLang}
          />
          <Socials
            socials={socials}
            backgroundState={[background, setBackground]}
          />
        </div>
      </div>
      <MobileHeader
        nav={nav}
        subnav={subnav}
        backgroundState={[background, setBackground]}
        imageState={[visibleImage, setVisibleImage]}
      />
    </header>
  );
};

Header.defaultProps = {};

export default Header;
